@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

:root {
  --box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

* {
  box-sizing: border-box;
}

body {
  /* background-color: #032A49; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  min-height: 100vh;
  margin: 0;
  overflow-x: scroll;
  color: #032A49;
}

/* Color Pallette */
/* .color-pallette {
  background: linear-gradient(60deg,#032A49, #616E9A, #9697B6, #C4C5DA, #CB9821)
} */

.app-container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
}

.main-content {
  position: relative;
  /* margin-top: 5rem; */
  z-index: -20;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: calc(100vw - 25rem);
  flex-grow: 1;
  background-color: #fff;
  border-radius: 0;
  transition: margin-left 0.4s ease;
  /* padding: 20px; */
  overflow-y: scroll;
}
.details-container {
  width: 100%;
}

/*--====== Sidebar ======--*/
#sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  max-width: 300px;
  width: 80%;
  height: 100%;
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0 10px 20px -4px #000;
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-100%);
  transition: opacity 0.3s ease, visibility 0.2s ease, transform 0.3s ease;
}
/* when the sidebar has 'show' class */
#sidebar.show {
  pointer-events: all;
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}
.sidebar_content {
  padding: 2.8rem 0;
  pointer-events: none;
  /* so that the Sidebar does not get closed while clicking on sidebar_content */
}
.sidebar_content a {
  pointer-events: all;
  /* so that all the a inside sidebar_content are clickable */
}
.sidebar_body {
  border-top: 1px dashed var(--text-color);
  border-bottom: 1px dashed var(--text-color);
}
.side_navlinks ul {
  display: grid;
}
.side_navlinks li {
  list-style-type: none;
  padding: 1rem 0.5rem;
  width: 100%;
}
.side_navlinks li a {
  text-transform: uppercase;
  letter-spacing: 1px;
  opacity: 0.8;
  padding: 0.5rem;
  transition: all 0.3s ease;
  color: #032A49;
  text-decoration: none;
}
.side_navlinks li:hover {
  opacity: 1;
  text-decoration: none;
  border-top: 1px solid #032A49;
  border-bottom: 1px solid #032A49;
}

.side_navlinks a:hover {
  color: #032A49;
  font-weight: bolder;
}


/*---- Sidebar-Toggler ----*/
.sidebar_toggler {
  position: fixed;
  top: 1.5rem;
  left: 0;
  z-index: 2000;
  width: 2rem;
  height: 1rem;
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  overflow: hidden;
  transition: left 0.3s ease;
}

.sidebar_toggler span {
  background-color: #032A49;
  width: 100%;
  height: 2.4px;
  transition: all 0.3s ease;
  pointer-events: none;
}
/* if the sidebar has 'show' class then their adjacent-sibling (i.e., sidebar_toggler) will... */
#sidebar.show + .sidebar_toggler {
  justify-content: center;
}
#sidebar.show + .sidebar_toggler span {
  margin-top: -1.2px;
  margin-bottom: -1.2px;
}
#sidebar.show + .sidebar_toggler span:first-child {
  transform: rotate(45deg);
}
#sidebar.show + .sidebar_toggler span:nth-child(2) {
  opacity: 0;
  transform: translateX(-100%);
}
#sidebar.show + .sidebar_toggler span:last-child {
  transform: rotate(-45deg);
}


/* CSS v1 */
.container {
  margin: auto 0;
  width: 90vw;
  display: flex;
  justify-content: space-between;
}

.subcontainer {
  margin: 0.5rem;
  width: 26rem;
}

.subcontainer-search {
  margin: 0.5rem 0.5rem;
  width: 25%;
  transition: width 0.3s ease;
}

.subcontainer-search:hover {
  width: 50vw;
}

.transaction-list {
  margin: 0 2.5rem;
  width: 80rem;
}

h1 {
  color: #032A49;
  text-transform: uppercase;
  font-size: 2rem;
  letter-spacing: 1px;
  margin: 0;
  padding-bottom: 1rem;
}

h2 {
  color: #032A49;
  text-transform: uppercase;
  font-size: 1.5rem;
  margin: 0.5rem;
  padding: 0.5rem 0;
}

h3 {
  border-bottom: 1px solid #bbb;
  padding-bottom: 10px;
  margin: 0 0 10px;
  display: flex;
  justify-content: space-between;
}

h4 {
  margin: auto 0 5px 0;
  text-transform: uppercase;
}

.balance-container {
  background-color: #fff;
  box-shadow: var(--box-shadow);
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  border-radius: 0.5rem;
}

.inc-exp-container {
  background-color: #fff;
  box-shadow: var(--box-shadow);
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 0;
  border-radius: 0.5rem;
}

.person-inc-exp-container {
  background-color: #fff;
  box-shadow: 8px 3px 3px #dedede;
  padding: 0 1rem;
  display: inline-flex;
  margin: 0 0 1rem 0;
  max-height: 50px;
  border-radius: 0 0 1rem 1rem;
}

.inc-exp-container > div {
  flex: 1;
  text-align: center;
}

.inc-exp-container > div:first-of-type {
  border-right: 1px solid #dedede;
}

.month-container {
  width: 100%;
  background-color: #fff;
  box-shadow: var(--box-shadow);
  padding: 2rem;
  display: flex;
  justify-content: space-between;
}

.money {
  font-size: 20px;
  letter-spacing: 1px;
  margin: 5px 0;
  padding: 0 0.25rem;
}

.balance.plus {
  font-size: 30px;
  color: #2ecc71;
}

.balance.minus {
  font-size: 30px;
  color: #D2042D;
}

.money.plus {
  color: #2ecc71;
}

.money.minus {
  color: #D2042D;
}

.monthly-money {
  font-size: 20px;
  letter-spacing: 1px;
  margin: 5px 0;
}

label {
  display: inline-block;
  margin: 0 10px 0 0;
}

input[type='text'],
input[type='date'],
input[type='number'],
input[type='email'],
input[type='password'],
select,
textarea {
  border-radius: 6px;
  display: block;
  font-size: 16px;
  padding: 10px;
  width: 100%;
  margin: 10px 0;
}

select.minimal {
  appearance: none;
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
}

.btn {
  cursor: pointer;
  background-color: #FFC107;
  box-shadow: var(--box-shadow);
  color: #032A49;
  border: 0;
  border-radius: 6px;
  display: block;
  font-size: 16px;
  padding: 10px;
  margin: 8px 0;
  width: 100%;
}

.btn:hover {
  font-weight: bold;
}

.btn:focus,
.delete-btn:focus {
  outline: 0;
}

.logout-btn {
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 19.5px;
  background-color: #fff;
  font-size: 1rem;
  padding: 0.3rem 0.5rem;
}

.logout-btn:hover {
  background-color: #032A49;
  color: #fff;
}

.pin-btn {
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 19.5px;
  background-color: #fff;
  font-size: 2rem;
  border: 2px solid #032A49;
}

.pin-btn:hover {
  rotate: -15deg;
  border: 2px solid #FFC107;
}

#scroll-list {
  height: 300px;
  overflow-y: scroll;
  margin: 0;
  padding: 0 2rem;
}

#search-list {
  height: 100px;
  overflow-y: scroll;
}

#search-item {
  width: 60vw;
  margin: 5px 0;
}

.list,
.month-list,
.card {
  list-style-type: none;
  padding: 0;
  margin-bottom: 1rem;
}

.list li,
.month-list li,
.card li {
  background-color: #fff;
  box-shadow: var(--box-shadow);
  color: #333;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 10px;
  margin: 10px 0;
}

.list li.plus, 
.private-list li.plus {
  border-right: 5px solid #2ecc71;
}

.list li.minus,
.private-list li.minus {
  border-right: 5px solid #D2042D;
}

.list li.private-plus {
  border-right: 5px solid #2ecc71;
  background-color: #555555;
  color: #fff;
}

.list li.private-minus {
  border-right: 5px solid #D2042D;
  background-color: #555555;
  color: #fff;
}

.card {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0;
  margin-bottom: 1rem;
  border-right: #dedede 1px solid;
  border-left: #dedede 1px solid;
  overflow-x: scroll;
}

.card li {
  background-color: #fff;
  box-shadow: var(--box-shadow);
  color: #333;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: 0.5rem;
  margin: 0.5rem;
  border-radius: 6px;
  width: 10rem;
}

.delete-btn {
  cursor: pointer;
  background-color: #D2042D;
  border: 0;
  color: #fff;
  font-size: 1rem;
  width: 20px;
  height: 20px;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-100%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.edit-btn {
  cursor: pointer;
  background-color: #2ecc71;
  border: 0;
  color: #fff;
  font-size: 1rem;
  width: 20px;
  height: 20px;
  padding: 0;
  position: absolute;
  top: 24%;
  left: 0;
  transform: translate(-100%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.list li:hover .delete-btn, .list li:hover .edit-btn {
  opacity: 1;
}

span {
  width: 15rem;
}

.record-item {
  width: 30rem;
}

.amount-item {
  width: 10rem;
  text-align: right;
}

.date-item, .payer-item, .category-item {
  width: 10rem;
  text-align: center;
}

.list-heading {
  padding: 0 40px 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #bbb;
}

.search-bar {
  width: 50rem;
  height: 3rem;
  margin-top: 0;
}

#outlined-basic {
  height: 1rem;
  margin: 0;
  border: none;
}

.filter-buttons {
  width: 50%;
  font-size: 16px;
  text-align: end;
}

.navbar {
  z-index: 1;
  position: fixed;
  top: 0;
  right: 2rem;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 1px 1px 5px #032A49;
  border-radius: 0 0 0.5rem 0.5rem;
  padding: 0.2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 70vw;
}

.navbar a {
  float: left;
  color: #808080;
  text-align: center;
  padding: 0.5rem 1rem;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  /* border: 2px solid #032A49;
  border-radius: 32px; */
}

.navlink:hover,
.navbar a:hover,
.navlink-extend:hover,
.dropdown-navlink:hover {
  color: #FFC107;
}

.navlink-active {
  color: #032A49;
  background-color: #FFC107;
}

.dropdown-content {
  display: none;
  position: absolute;
}

.navlink-extend:hover .dropdown-content {
  display: flex;
  flex-direction: column;
  width: 8rem;
}

.dropdown-content .dropdown-navlink {
  display: block;
  position: relative;
  float: none;
  background-color: #fff;
  border-radius: 0;
  border: none;
  border-left: solid 4px #032A49;
  margin: 0 5px;
  box-shadow: 8px 3px 3px #dedede;
  font-size: 0.9rem;
  z-index: 1;
  width: 100%;
}

.dropdown-content .dropdown-navlink:first-of-type {
  margin: 10px 5px 0;
}

.dropdown-content .dropdown-navlink:last-of-type {
  border-radius: 0 0 1rem 1rem;
}

.form-control {
  /* padding: 0 0.25rem; */
  width: 49%;
}

.form-control-small {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

/* Styling for News Pocket */

.news-app {
  width: 60rem;
  display: grid;
  gap: 1em;
  margin: 3em;
}
.news-form {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1em;
}
.news-button {
  cursor: pointer;
  background: #f7f7f7;
  color: #032A49;
  font: inherit;
  border: 1px solid #032A49;
  border-radius: 6px;
  outline: none;
  padding: 0.5em 1.5em;
}
.news-button:hover {
  background: #FFC107;
  font-weight: bold;
  border: none;
}
input, select {
  border: 1px solid #dedede;
  border-radius: 6px;
  font: inherit;
  padding: 0.5em;
  outline: none;
}
input:focus {
  outline: auto #FFC107;
}
.news-item {
  width: 100%;
  padding: 1em;
  list-style-type: none;
  border-radius: 6px;
  margin-bottom: 1em;
  background: #fff;
  box-shadow: 0px 2px 6px 2px #0002;
}
.news-item .news-title {
  font-size: 100%;
}
.news-item .news-description {
  margin: 1em 0;
}
.news-item .news-thumbnail {
  float: right;
  box-shadow: 0px 1px 1px 1px #0002;
  border-radius: 12px;
  margin-left: 1em;
  margin-bottom: 1em;
}
.news-item .news-meta {
  font-size: 80%;
  font-weight: bold;
  color: #555;
}
.news-item .news-provider-thumbnail {
  margin-right: 0.5em;
}
.news-item .news-meta > *:not(:last-child) {
  margin-right: 2em;
}

/* Login CSS */

.login-container {
  position: absolute;
  margin: 10vh 0 0 60vw;
  padding: 5rem 3rem;
  width: 30vw;
  height: 80vh;
  /* border: solid 2px #032A49; */
  border-radius: 1rem;
  background-color: #fff;
}

.password-wrapper {
  position: relative;
}

.password-wrapper input {
  width: 100%;
  padding-right: 40px;
}

.toggle-password {
  position: absolute;
  top: 55%;
  right: 1rem;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
}

.add-new-btn {
  position: fixed;
  top: 5rem;
  right: 1.5rem;
  color: #fff;
  border-color: #032A49;
  background-color: #032A49;
  border: solid 1px #808080;
  border-radius: 1rem;
  cursor: pointer;
}

.material-symbols-rounded {
  width: fit-content;
  padding: 0 0.5rem;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px 40px;
  border-radius: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.popup-content p {
  margin-bottom: 20px;
  font-size: 16px;
}

.popup-content button {
  background-color: #032A49;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 0.5rem;
  cursor: pointer;
}

.popup-content button:hover {
  background-color: #616E9A;
}

/* SCROLLING TEXT */

#scroll-container {
  margin: auto 1rem;
  border-left: #dedede 1px solid;
  overflow: hidden;
  width: 25rem;
  height: 70%;
}

#scroll-text {
  /* margin: 0.5rem; */
  padding: 0.2rem 0.5rem;
  text-transform: none;
  width: max-content;
  
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  
  -moz-animation: my-animation 50s linear infinite;
  -webkit-animation: my-animation 50s linear infinite;
  animation: my-animation 50s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from { -moz-transform: translateX(100%); }
  to { -moz-transform: translateX(-100%); }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from { -webkit-transform: translateX(100%); }
  to { -webkit-transform: translateX(-100%); }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

/* Stylings for Lunch Order */

.lunch-form-control {
  padding: 0 0.5rem;
}

.lunch-form-control-small {
  display: flex;
  justify-content: space-between;
}

.lunch-form-control-small label {
  display: inline-block;
  padding: 1rem 0.5rem;
}

.lunch-form-control-small input {
  margin: 0.25rem 0;
}

.lunch-menu ul {
  padding: 0 1rem;
}

.lunch-menu li {
  background-color: #fff;
  box-shadow: var(--box-shadow);
  color: #333;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 0.8rem 0.8rem 0.5rem;
  margin: 10px 0;
  height: 4rem;
  width: 12vw;
}

.lunch-btn {
  width: 30vw;
  margin: 0.5rem 30vw;
  cursor: pointer;
  background-color: #FFC107;
  box-shadow: var(--box-shadow);
  color: #032A49;
  border: 0;
  border-radius: 6px;
  font-size: 16px;
  padding: 10px;
}

.lunch-btn:hover {
  font-weight: bold;
}

.lunch-order-container {
  background-color: #fff;
  box-shadow: var(--box-shadow);
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  border-radius: 0.5rem;
  width: 90vw;
  margin: 1rem;
  padding: 1rem;
}

/* CSS Loader */
.loader {
  position: absolute;
  top: 50%;
  left: 40%;
  margin-left: 10%;
  transform: translate3d(-50%, -50%, 0);
}
.dot {
  width: 24px;
  height: 24px;
  background: #032A49;
  border-radius: 100%;
  display: inline-block;
  animation: slide 1s infinite;
}
.dot:nth-child(1) {
  animation-delay: 0.1s;
  background: #616E9A;
}
.dot:nth-child(2) {
  animation-delay: 0.2s;
  background: #9697B6;
}
.dot:nth-child(3) {
  animation-delay: 0.3s;
  background: #C4C5DA;
}
.dot:nth-child(4) {
  animation-delay: 0.4s;
  background: #CB9821;
}
.dot:nth-child(5) {
  animation-delay: 0.5s;
  background: #FFC107;
}
@-moz-keyframes slide {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes slide {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
@-o-keyframes slide {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes slide {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}

/* 404 Not Found CSS */
.content {
  width: 300px;
  height: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15vh 25vw;
}
.content .planet {
  width: 65%;
  height: 65%;
  background-color: #032A49;
  border-radius: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  transform-origin: center center;
  box-shadow: inset 2px -10px 0px rgba(0, 0, 0, 0.1);
  animation: planet 5s ease infinite alternate;
  /* planet ring */
  /* to cover the back of the ring */
  /* planet spots */
}
@keyframes planet {
  0% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}
.content .planet .ring {
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 100%;
  background-color: #FFC107;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: 33% center;
  box-shadow: 2px -10px 0px rgba(0, 0, 0, 0.1), inset -5px -10px 0px rgba(0, 0, 0, 0.1);
  animation: ring 3s ease infinite;
  /* small ball */
  /* inner ring */
}
@keyframes ring {
  0% {
    transform: rotateX(110deg) rotateZ(0deg) translate(-50px, 5px);
  }
  100% {
    transform: rotateX(110deg) rotateZ(360deg) translate(-50px, 5px);
  }
}
.content .planet .ring:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 30px;
  border-radius: 100%;
  background-color: #5c8f34;
  z-index: 2;
  left: calc(0px - 5px);
  box-shadow: inset -3px 3px 0px rgba(0, 0, 0, 0.2);
}
.content .planet .ring:after {
  content: "";
  position: absolute;
  width: 240px;
  height: 240px;
  border-radius: 100%;
  background-color: #5c8f34;
  box-shadow: inset 2px -10px 0px rgba(0, 0, 0, 0.1);
}
.content .planet .cover-ring {
  position: absolute;
  width: 100%;
  height: 50%;
  border-bottom-left-radius: 80%;
  border-bottom-right-radius: 80%;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  transform: translate(0px, -17px);
  background-color: #032A49;
  z-index: 2;
  box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.1);
}
.content .planet .spots {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;
}
.content .planet .spots span {
  width: 30px;
  height: 30px;
  background-color: #FFC107;
  position: absolute;
  border-radius: 100%;
  box-shadow: inset -2px 3px 0px rgba(0, 0, 0, 0.3);
  animation: dots 5s ease infinite alternate;
}
@keyframes dots {
  0% {
    box-shadow: inset -3px 3px 0px rgba(0, 0, 0, 0.3);
  }
  100% {
    box-shadow: inset 3px 3px 0px rgba(0, 0, 0, 0.3);
  }
}
.content .planet .spots span:nth-child(1) {
  top: 20px;
  right: 50px;
}
.content .planet .spots span:nth-child(2) {
  top: 40px;
  left: 50px;
  width: 15px;
  height: 15px;
}
.content .planet .spots span:nth-child(3) {
  top: 80px;
  left: 20px;
  width: 25px;
  height: 25px;
}
.content .planet .spots span:nth-child(4) {
  top: 80px;
  left: 90px;
  width: 40px;
  height: 40px;
}
.content .planet .spots span:nth-child(5) {
  top: 160px;
  left: 70px;
  width: 15px;
  height: 15px;
}
.content .planet .spots span:nth-child(6) {
  top: 165px;
  left: 125px;
  width: 10px;
  height: 10px;
}
.content .planet .spots span:nth-child(7) {
  top: 90px;
  left: 150px;
  width: 15px;
  height: 15px;
}
.content p {
  color: #bacbd9;
  font-size: 14px;
  z-index: 2;
  position: absolute;
  bottom: -4rem;
  animation: text 4s ease infinite;
  text-align: center;
}
@keyframes text {
  0% {
    transform: translateX(-30px);
    letter-spacing: 0px;
    color: #5c8f34;
  }
  25% {
    letter-spacing: 3px;
    color: #032A49;
  }
  50% {
    transform: translateX(30px);
    letter-spacing: 0px;
    color: #5c8f34;
  }
  75% {
    letter-spacing: 3px;
    color: #032A49;
  }
  100% {
    transform: translateX(-30px);
    letter-spacing: 0px;
    color: #5c8f34;
  }
}

/* Accordion */

.accordion {
  float: right;
}

.accordion summary {
  color: #032A49;
  text-align: center;
  margin: 0 0.5rem;
  padding: 0.5rem 1rem;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  border: 2px solid #032A49;
  border-radius: 32px;
}

.accordion summary:hover {
  color: #FFC107;
  background-color: #032A49;
}

.accordion details {
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.accordion details .dropdown-navlink {
  display: block;
  position: relative;
  float: none;
  background-color: #fff;
  border-radius: 0;
  border: none;
  border-left: solid 4px #032A49;
  margin: 0 2rem;
  box-shadow: 8px 3px 3px #dedede;
  font-size: 0.9rem;
  width: 70%;
}

.accordion details .dropdown-navlink:last-of-type {
  border-radius: 0 0 1rem 1rem;
}

.accordion details summary {
  font-weight: bold;
  list-style-type: none;
}

.accordion details .content {
  display: none;
}

/* Ticket CSS */

.high-dot {
  width: 1rem;
  height: 1rem;
  border: 0;
  border-radius: 50%;
  background-color: #D2042D;
  margin-top: 2px;
}

.medium-dot {
  width: 1rem;
  height: 1rem;
  border: 0;
  border-radius: 50%;
  background-color: #FFC107;
  margin-top: 2px;
}

.low-dot {
  width: 1rem;
  height: 1rem;
  border: 0;
  border-radius: 50%;
  background-color: #2ecc71;
  margin-top: 2px;
}

.initial-avatar {
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #d1d5db;
  color: #fff;
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
}

/* Rolling Text CSS */

.text-container {
  margin: 2rem;
  text-align: center;
  display: flex;
  flex-direction: row;
  width: fit-content;
  color: #032A49;
}

.text-container-firstchar {
  font-size: 5rem;
  color: #FFC107;
}

.text-container-b {
  font-size: 3rem;
  padding: 2rem 1px;
}

.h1-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/* Image */

.img-container {
  width: 100vw;
  height: 100vh;
}

/* Profile View */
.edit-icon {
  color: #808080;
  font-size: 1.5rem;
  border: 1px solid #808080;
  border-radius: 1rem;
}

.edit-icon:hover {
  color: #032A49;
  border: 1px solid #032A49;
}

.profile-layout {
  position: relative;
  top: 4rem;
  left: 10vw;
  display: flex;
  flex-direction: column;
  width: 70vw;
}

.profile-details-container {
  position: relative;
  top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 0.5rem;
}

.subcontainer-25 {
  margin: 0.5rem 2.5%;
  width: 25%;
}

.subcontainer-70 {
  margin: 0.5rem 2.5%;
  width: 70%;
}

.subcontainer-45 {
  width: 47.5%;
}

.profile-part {
  display: inline-flex;
  justify-content: space-between;
  margin-top: 1rem;
  width: 100%;
}

.profile-section {
  width: 100%;
  background-color: #fff;
  box-shadow: var(--box-shadow);
  padding: 1rem 2rem 2rem 3rem;
  margin: 1rem 0;
  border-radius: 0.5rem;
}

.profile-section h3 {
  width: 100%;
  margin: 0;
}

.profile-span-50 {
  width: 50%;
}

.profile-span-70 {
  width: 70%;
}

.profile-span-30 {
  width: 30%;
}

.profile-span-33 {
  width: 33.3%;
}

.profile-span-25 {
  width: 25%;
}

.profile-span-70 b,
.profile-span-50 b,
.profile-span-33 b,
.profile-span-30 b,
.profile-span-25 b {
  display: block;
  color: #808080;
  margin-top: 6px;
}

.icon-container {
  padding: 0 0 6px;
}

.summary-icon-container {
  padding: 12px 0 0;
}

.photo-background {
  position: fixed;
  top: 0;
  left: 5rem;
  width: 20vw;
  height: 100vh;
  background: linear-gradient(-80deg, #FFC107, #032A49);
  border-radius: 0 0.5rem 0.5rem 0;
  box-shadow: 5px 5px 5px #032A49;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  color: #fff;
  margin: 0 2rem 0 0;
  box-shadow: var(--box-shadow);
}

.summary-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.MuiTouchRipple-root {
  width: 100%;
}

.MuiAppBar-root {
  background: linear-gradient(45deg, #032A49, #032A49, #032A49, #FFC107);
}

/* Pokepet */

.pet-section {
  display: inline-flex;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem 0;
  overflow-x: scroll;
}

.pet-container {
  min-width: 99%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0.25rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: var(--box-shadow);
}

.pet-section::-webkit-scrollbar {
  height: 0.25rem;
}

.pet-section::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: linear-gradient(60deg, #032A49, #FFC107);
}

.img-wrap {
  position: relative;
}

.img-description {
  position: absolute;
  bottom: 10%;
  right: 0;
  left: 5%;
  visibility: hidden;
  opacity: 0;
  text-align: center;
  font-size: 0.75rem;
  transition: opacity .2s, visibility .2s;
  background-color: #032A49;
  color: #fff;
  border-radius: 10px;
  width: 90%;
}

.img-wrap:hover .img-description {
  visibility: visible;
  opacity: 0.8;
}

/* Habit */

.habit-form {
  margin: 1rem;
  width: 15vw;
}

.habit-calendar {
  display: flex;
  margin: 0.5rem 0;
  justify-content: space-between;
}

.habit-container {
  width: 22rem;
  padding: 1rem;
}

.MuiBadge-root {
  width: fit-content;
}

.MuiDateCalendar-root {
  min-width: fit-content;
}

.MuiDayCalendar-header span {
  justify-content: center;
}

/* TO DO CSS */

.todo-edit-btn {
  cursor: pointer;
  background-color: #2ecc71;
  color: #fff;
  font-size: 0.75rem;
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 10px;
  margin-top: 0.25rem;
}

/* Calendar Heatmap */
.cal-heatmap-container {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Growth Diary */

.growth-card {
  border: 1px solid #032A49;
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 2rem;
}

.green-card {
  background: linear-gradient(12deg, #2ecc71, #fff, #fff, #fff, #fff, #fff);
}

.red-card {
  background: linear-gradient(12deg, #D2042D, #fff, #fff, #fff, #fff, #fff);
}

.yellow-card {
  background: linear-gradient(12deg, #FFC107, #fff, #fff, #fff, #fff, #fff);
}

.list-container {
  width: 100%;
  transition: all 0.4s ease;
}

.container-narrow {
  width: 50%;
  position: relative;
  left: 0;
  transition: all 0.4s ease;
}

/* Responsive CSS */

@media (max-width: 480px) {
  :root,
  body,
  .container,
  .subcontainer,
  .transaction-list,
  h3,
  .lunch-menu,
  .list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90vw;
    margin: 0.5rem 2vw;
  }
  .navbar {
    position: fixed;
    top: 0;
    right: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60vw;
    margin: 0 2vw;
    padding: 0 0.5rem;
    border-radius: 0.5rem;
  }
  .profile-layout {
    left: 1.5rem;
    top: 10rem;
    width: 60vw;
  }
  .photo-background {
    left: 0;
    width: 30vw;
  }
  .summary-container {
    top: 10rem;
    text-align: center;
  }
  .details-container {
    width: 50;
  }
  .sidebar_toggler {
    top: 2rem;
  }
  .login-container {
    width: 70vw;
    height: fit-content;
    margin: 15vh 12.5vw 0;
    background-clip: padding-box;
    border: 1px solid transparent;
    /* border-radius: 1rem; */
  }
  .login-container:before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    margin: -1px;
    background-image: linear-gradient(-15deg, #032A49, #032A49, #fff, #fff, #fff);
    border-radius: inherit;
  }
  h2,
  .inc-exp-container,
  .balance-container,
  .subcontainer-search,
  .filter-buttons,
  .subcontainer-search,
  .subcontainer-search:hover,
  .list li.private-plus,
  .list li.private-minus,
  .list li.plus,
  .private-list li.plus,
  .list li.minus,
  .private-list li.minus,
  .subcontainer-25,
  .subcontainer-70,
  #search-item {
    width: 100%;
  }
  .filter-buttons {
    text-align: center;
  }
  div {
    width: 100%;
    margin: 0.2rem 0;
  }
  .logout {
    width: auto;
  }
  #scroll-container {
    border: none;
  }
  .person-inc-exp-container {
    border-radius: 0.5rem;
  }
  .list-heading {
    display: none;
  }
  .record-item,
  .amount-item,
  .date-item,
  .payer-item,
  .category-item
  {
    width: 100%;
    text-align: center;
    margin: 2px 0;
  }
  .date-item,
  .payer-item,
  .category-item {
    font-style: italic;
  }
  .amount-item {
    font-size: larger;
  }
  .list li {
    flex-direction: column;
  }
  .delete-btn {
    opacity: 1;
  }
  .lunch-order-container
  {
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 80vw;
  }
  .lunch-menu ul,
  .lunch-menu ul li {
    width: 100%;
  }
  .loader {
    padding: 35%;
  }
  .content {
    margin: 0 10vw;
  }
  .accordion details .dropdown-navlink {
    width: 70vw;
    margin: 0 10vw;
  }
  .h1-text {
    flex-direction: column;
  }
  .text-container-b {
    padding: 1.5rem 1px;
    font-size: 2rem;
  }
  .text-container-firstchar {
    font-size: 4rem;
  }
  .img-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: auto;
    margin: 0;
  }
  .profile-part {
    display: block;
    width: 90%;
    margin: 0 5vw;
  }
  .icon-container {
    padding: 10px 6px 0 0;
  }
  .habit-calendar {
    display: block;
    margin-left: 1rem;
  }
  .habit-container {
    width: 100%;
    padding: 1rem;
    padding-left: 0;
  }
  .habit-form {
    width: 100%;
    padding: 0 1rem 0 0;
  }
  .img-wrap {
    width: fit-content;
  }
  .MuiDayCalendar-header span {
    width: 40px;
  }
  .MuiDayCalendar-weekContainer p {
    min-width: 40px;
  }
  .cal-heatmap-container {
    width: 100%;
    overflow-x: scroll;
  }
}
