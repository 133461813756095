  .timeline-container {
    position: relative;
    width: 92%;
    margin: 20px auto;
    padding: 1rem 0;
  }
  
  .timeline-bar {
    position: relative;
    height: 8px;
    background: #ddd;
    width: 102%;
    border-radius: 4px;
    overflow: hidden;
  }
  
  .progress-bar {
    height: 8px;
    background: #032A49;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .milestones {
    position: relative;
    display: flex;
    justify-content: space-between;
    /* margin-top: 10px; */
  }
  
  .milestone {
    position: absolute;
    top: -0.75rem;
    text-align: center;
    transform: translateX(-50%);
  }

  /* .milestone :first-child {
    text-align: left;
  } */
  
  .milestone .dot {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #bbb;
    display: inline-block;
    margin: 0 auto;
    top: 0.1rem;
  }

  .milestone.past .dot {
    background: #032A49;
    animation: pulse 1.5s infinite ease-in-out;
  }
  
  .milestone.future .dot {
    background: #FFC107;
  }
  
  .label {
    width: fit-content;
    font-size: 12px;
    margin-top: 5px;
    display: block;
    white-space: nowrap;
  }

  .above-text {
    position: relative;
    bottom: 3.5rem; /* Moves text above the dot */
  }