  .limiter {
    position: relative;
    display: flex;
    justify-content: center;
    width: 90%;
    margin: 5.5rem auto;
  }
  
  .limiter-narrow {
    position: relative;
    display: flex;
    justify-content: center;
    width: 50%;
    margin: 5.5rem auto;
  }
  
  .container-table100 {
    width: 100%;
    min-height: 100vh;
    background: #c850c0;
    background: -webkit-linear-gradient(45deg, #4158d0, #c850c0);
    background: -o-linear-gradient(45deg, #4158d0, #c850c0);
    background: -moz-linear-gradient(45deg, #4158d0, #c850c0);
    background: linear-gradient(45deg, #4158d0, #c850c0);
  
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 33px 30px;
  }
  
  .wrap-table100 {
    width: 100%;
  }
  
  table {
    border-spacing: 1;
    border-collapse: collapse;
    background: white;
    border-radius: 0.5rem;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  table * {
    position: relative;
  }
  table td, table th {
    padding-left: 8px;
  }
  table thead tr {
    height: 60px;
    background: #032A49;
  }
  table tbody tr {
    height: 50px;
  }
  table tbody tr:last-child {
    border: 0;
  }
  table td, table th {
    text-align: left;
  }
  table td.l, table th.l {
    text-align: right;
  }
  table td.c, table th.c {
    text-align: center;
  }
  table td.r, table th.r {
    text-align: center;
  }
  
  
  .table100-head th{
    font-size: 1rem;
    text-transform: uppercase;
    color: #fff;
    line-height: 1.2;
  }
  
  tbody tr:nth-child(even) {
    background-color: #f5f5f5;
  }
  
  tbody tr {
    font-size: 14px;
    color: #808080;
    line-height: 1.2;
    font-weight: unset;
  }
  
  tbody tr:hover {
    color: #555555;
    background-color: #f5f5f5;
    cursor: pointer;
  }
  
  .column0 {
    width: 3%;
    padding-left: 1.5rem;
  }
  
  .column1 {
    width: 15%;
    padding-left: 1.5rem;
  }
  
  .column2 {
    width: 12%;
  }
  
  .column3 {
    width: 10%;
  }
  
  .column4 {
    width: 16%;
  }
  
  .column5 {
    width: 12%;
  }
  
  .column6 {
    width: 10%;
  }

  .column7 {
    width: 12%;
  }

  .column8 {
    width: 12%;
  }

  .column9 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: fit-content;
    padding-right: 1.5rem;
  }
/* 
  .column10 {
    width: 3%;
  }

  .column11 {
    width: 5%;
    padding-right: 1.5rem;
  } */
  
  .sidebar-table-responsive {
  display: block;
  border: 1px solid #f5f5f5;
  }

  .sidebar-table-responsive > *,
  .sidebar-table-responsive tr,
  .sidebar-table-responsive td,
  .sidebar-table-responsive th {
    display: block;
  }

  .sidebar-table-responsive thead {
    display: none;
  }

  .sidebar-table-responsive tbody tr {
    height: auto;
    padding: 37px 0;
  }

  .sidebar-table-responsive tbody tr td {
    padding-left: 40% !important;
    margin-bottom: 24px;
  }

  .sidebar-table-responsive tbody tr td:last-child {
    margin-bottom: 0;
  }

  .sidebar-table-responsive tbody tr td:before {
    font-size: 14px;
    color: #999999;
    line-height: 1.2;
    font-weight: unset;
    position: absolute;
    width: 40%;
    left: 30px;
    top: 0;
  }

  .sidebar-table-responsive tbody tr td:nth-child(1):before {
    content: "Firstname";
  }

  .sidebar-table-responsive tbody tr td:nth-child(2):before {
    content: "Lastname";
  }

  .sidebar-table-responsive tbody tr td:nth-child(3):before {
    content: "Mobile";
  }

  .sidebar-table-responsive tbody tr td:nth-child(4):before {
    content: "Email";
  }

  .sidebar-table-responsive tbody tr td:nth-child(5):before {
    content: "Designation";
  }

  .sidebar-table-responsive tbody tr td:nth-child(6):before {
    content: "Team";
  }

  .sidebar-table-responsive tbody tr td:nth-child(7):before {
    content: "Department";
  }

  .sidebar-table-responsive tbody tr td:nth-child(8):before {
    content: "Type";
  }

  .column4,
  .column5,
  .column6 {
    text-align: left;
  }

  tbody tr {
    font-size: 14px;
  }
  
  @media screen and (max-width: 992px) {
    table {
      display: block;
    }
    table > *, table tr, table td, table th {
      display: block;
    }
    table thead {
      display: none;
    }
    table tbody tr {
      height: auto;
      padding: 37px 0;
    }
    table tbody tr td {
      padding-left: 40% !important;
      margin-bottom: 24px;
    }
    table tbody tr td:last-child {
      margin-bottom: 0;
    }
    table tbody tr td:before {
      font-size: 14px;
      color: #999999;
      line-height: 1.2;
      font-weight: unset;
      position: absolute;
      width: 40%;
      left: 30px;
      top: 0;
    }
    table tbody tr td:nth-child(1):before {
      content: "Firstname";
    }
    table tbody tr td:nth-child(2):before {
      content: "Lastname";
    }
    table tbody tr td:nth-child(3):before {
      content: "Mobile";
    }
    table tbody tr td:nth-child(4):before {
      content: "Email";
    }
    table tbody tr td:nth-child(5):before {
      content: "Designation";
    }
    table tbody tr td:nth-child(6):before {
      content: "Team";
    }
    table tbody tr td:nth-child(7):before {
      content: "Department";
    }
    table tbody tr td:nth-child(8):before {
      content: "Type";
    }

    .column4,
    .column5,
    .column6 {
      text-align: left;
    }
  
    .column4,
    .column5,
    .column6,
    .column1,
    .column2,
    .column3 {
      width: 100%;
    }
  
    tbody tr {
      font-size: 14px;
    }
  }
  
  @media (max-width: 576px) {
    .container-table100 {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  /* Detail Sidebar */

  .detail-sidebar {
    position: fixed;
    top: 0;
    right: 0;
    width: 45vw;
    height: 100%;
    background: #f4f4f4;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    overflow-y: auto;
    padding: 20px;
    transform: translateX(0);
    transition: transform 0.3s ease-in-out;
  }
  
  .detail-sidebar-content {
    display: flex;
    flex-direction: column;
  }
  
  .close-btn {
    align-self: flex-end;
    background: transparent;
    border: none;
    color: #032A49;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .close-btn:hover {
    color: #FFC107;

  }
  
  .view-more-btn {
    background: none;
    border: none;
    color: #808080;
    margin: 1rem 0.25rem;
    padding: 0.1rem;
    border: solid 1px #808080;
    border-radius: 1rem;
    cursor: pointer;
    font-size: 1.25rem;
  }
  
  .view-more-btn:hover {
    color: #032A49;
    border: solid 1px #032A49;
  }

  @media screen and (max-width: 480px) {
    .detail-sidebar {
      width: 90vw;
    }
  }