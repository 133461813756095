@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.sidebar {
    position: fixed;
    z-index: 1000;
    top: 0;
    /* left: 0; */
    width: 270px;
    /* margin: 16px; */
    border-radius: 0 0.5rem 0.5rem 0;
    background: #032A49;
    height: 100vh;
    transition: all 0.4s ease;
  }
  .sidebar.collapsed {
    width: 5rem;
    border-radius: 0;
  }
  .sidebar .sidebar-header {
    display: flex;
    position: relative;
    padding: 1.5rem 1.2rem;
    align-items: center;
    justify-content: space-between;
  }
  .sidebar-header .header-logo img {
    width: 46px;
    height: 46px;
    display: block;
    object-fit: contain;
    border-radius: 50%;
  }
  .sidebar-header .toggler {
    height: 35px;
    width: 35px;
    color: #032A49;
    border: none;
    cursor: pointer;
    display: flex;
    background: #fff;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 0.4s ease;
  }
  .sidebar-header .sidebar-toggler {
    position: absolute;
    right: 1.2rem;
  }
  .sidebar-header .menu-toggler {
    display: none;
  }
  .sidebar.collapsed .sidebar-header .toggler {
    transform: translate(-4px, 65px);
  }
  .sidebar-header .toggler:hover {
    background: #dde4fb;
  }
  .sidebar-header .toggler span {
    font-size: 1.75rem;
    transition: 0.4s ease;
  }
  .sidebar.collapsed .sidebar-header .toggler span {
    transform: rotate(180deg);
  }
  .sidebar-nav .nav-list {
    list-style: none;
    display: flex;
    gap: 4px;
    padding: 0 15px;
    flex-direction: column;
    transform: translateY(15px);
    transition: 0.4s ease;
  }
  .sidebar.collapsed .sidebar-nav .primary-nav {
    transform: translateY(65px);
  }
  .sidebar-nav .nav-link {
    color: #fff;
    display: flex;
    gap: 12px;
    white-space: nowrap;
    border-radius: 8px;
    padding: 0.75rem 0.75rem 0.75rem 0.25rem;
    align-items: center;
    text-decoration: none;
    transition: 0.4s ease;
  }
  .sidebar.collapsed .sidebar-nav .nav-link {
    border-radius: 12px;
  }
  .sidebar .sidebar-nav .nav-link .nav-label {
    margin-left: 2rem;
    transition: opacity 0.3s ease;
  }
  .sidebar.collapsed .sidebar-nav .nav-link .nav-label {
    opacity: 0;
    pointer-events: none;
  }
  .sidebar-nav .nav-link:hover {
    color: #032A49;
    background: #fff;
  }
  .sidebar-nav .nav-item {
    position: relative;
  }
  .sidebar-nav .nav-tooltip {
    position: absolute;
    top: -10px;
    opacity: 0;
    color: #032A49;
    display: none;
    pointer-events: none;
    padding: 6px 12px;
    border-radius: 8px;
    white-space: nowrap;
    background: #fff;
    left: calc(100% + 25px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    transition: 0s;
  }
  .sidebar.collapsed .sidebar-nav .nav-tooltip {
    display: block;
  }
  .sidebar-nav .nav-item:hover .nav-tooltip {
    width: fit-content;
    opacity: 0.8;
    pointer-events: auto;
    transform: translateY(50%);
    transition: all 0.4s ease;
  }
  .sidebar-nav .secondary-nav {
    position: absolute;
    bottom: 30px;
    width: 100%;
  }
  .nav-link.active {
    color: #032A49;
    font-weight: bold;
  }
  /* Responsive media query code for small screens */
  @media (max-width: 480px) {
    .sidebar {
      position: fixed;
      z-index: 1000;
      top: 0;
      width: 4rem;
      overflow-y: hidden;
      scrollbar-width: none;
      border-radius: 0 0.5rem 0.5rem 0;
    }
    .sidebar.menu-active {
      overflow-y: auto;
    }
    .sidebar.collapsed {
      border-radius: 0 1rem 1rem 0;
    }
    .sidebar .sidebar-header {
      position: sticky;
      top: 0;
      z-index: 20;
      border-radius: 16px;
      background: #032A49;
      padding: 8px 10px;
    }
    .sidebar-header .header-logo img {
      width: 40px;
      height: 40px;
      margin-left: 4px;
    }
    .sidebar-header .sidebar-toggler,
    .sidebar-nav .nav-item:hover .nav-tooltip {
      display: none;
    }
    
    .sidebar-header .menu-toggler {
      position: absolute;
      display: flex;
      height: 30px;
      width: 30px;
      margin-left: 11px;
    }
    .sidebar-header .menu-toggler span {
      font-size: 1.3rem;
    }
    .sidebar .sidebar-nav .nav-list {
      padding: 0 10px;
    }
    .sidebar-nav .nav-link {
      gap: 10px;
      font-size: 0.94rem;
    }
    .sidebar-nav .nav-link .nav-icon {
      font-size: 1.37rem;
    }
    .sidebar-nav .secondary-nav {
      position: relative;
      bottom: 0;
      margin: 40px 0 30px;
    }
    .secondary-nav .nav-item:first-child {
      border-top: 1px solid #dedede;
      margin-top: 2rem;
      padding-top: 0.5rem;
    }
  }