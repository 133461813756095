    /* Tooltip Container */
  .tooltip-container {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  /* Tooltip Text */
  .tooltip {
    visibility: hidden;
    width: 120px;
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 5px;
    position: absolute;
    bottom: 150%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 12px;
  }

  /* Show Tooltip on Hover */
  .tooltip-container:hover .tooltip,
  .tooltip-container:focus-within .tooltip {
      visibility: visible;
      opacity: 1;
  }

  /* The switch - container */
  .switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
    margin: 0;
  }

  /* Hide default checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
    width: 34px;
  }

  /* Slider Circle */
  .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }

  /* Checked styles */
  input:checked + .slider {
    background-color: #4CAF50;
  }

  input:checked + .slider:before {
    transform: translateX(14px);
  }

  .leave-form-container {
    max-width: 100%;
    margin: auto;
    padding: 20px;
  }
  
  .leave-form-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  label {
    font-weight: bold;
    display: block;
    margin-top: 10px;
  }
  
  select, textarea, .date-picker {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .leave-dates-list {
    list-style: none;
    padding: 0;
  }
  
  .leave-dates-list li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-top: 5px;
    background-color: #fff;
  }

  /* Ensure the container is scrollable */
.leave-table-container {
  max-height: 50vh;
  position: relative; /* Important for sticky elements */
}

/* Custom Scrollbar */
.leave-table-container::-webkit-scrollbar {
  width: 8px;
}

.leave-table-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.leave-table-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Sticky header */
.leave-table thead {
  position: sticky;
  top: 0;
  background: white;
  z-index: 102; /* Higher than other elements */
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

/* Table styles */
.leave-table {
  width: 100%;
  min-width: 800px;
  border-collapse: collapse;
}

/* Ensure thead and tbody are separate */
.leave-table thead tr {
  display: table;
  width: 100%;
  table-layout: fixed; /* Ensures alignment */
}

/* Table rows must be block inside tbody */
.leave-table tbody {
  display: block;
  width: 100%;
  overflow: auto;
  max-height: 45vh; /* Adjusted height */
}

/* Ensures columns stay aligned */
.leave-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

/* Column Styling */
.leave-table th,
.leave-table td {
  border: 1px solid #ddd;
  text-align: center;
  padding: 10px;
}

/* Header Styles */
.leave-table th {
  background-color: #032A49;
  color: white;
  font-weight: bold;
}
  .status-pending {
    color: #FFC107;
    font-weight: bold;
  }
  
  .status-approved {
    color: green;
    font-weight: bold;
  }
  
  .status-rejected {
    color: red;
    font-weight: bold;
  }
  
  /* button {
    margin-top: 10px;
    padding: 8px;
    border: none;
    border-radius: 5px;
    background-color: #032A49;
    color: white;
    cursor: pointer;
  } */
  
  /* button:hover {
    background-color: #02577a;
  } */